<template>
  <static-page-chrome>
    <h1>Datenschutz­erklärung</h1>
    <p>
      <strong
        >KA Pass ist ein Angebot des Stadtjugendausschuss e.V.
        Karlsruhe.</strong
      >
    </p>
    <p>
      Hinweise zur Erhebung und Verarbeitung personenbezogener Daten bei Aufruf
      dieser Webseite finden Sie in der Datenschutzerklärung der Hauptseite
      <a href="https://www.stja.de/" target="_blank" rel="noreferrer noopener"
        >www.stja.de</a
      >, die über folgenden Link erreichbar ist:
      <a
        href="https://stja.de/datenschutz/"
        target="_blank"
        rel="noreferrer noopener"
        >https://stja.de/datenschutz/</a
      >
    </p>
  </static-page-chrome>
</template>

<script>
import StaticPageChrome from "~/src/components/common/layout/StaticPageChrome";

export default {
  name: "PagePrivacy",
  components: {
    StaticPageChrome,
  },
  head: {
    title: "Datenschutzerklärung",
  },
};
</script>
