<template>
  <el-popover
    placement="bottom-start"
    trigger="hover"
    width="500"
    append-to-body
    :popper-class="$style.popper"
    :disabled="commentCount === 0"
  >
    <div :class="$style.body">
      <div v-if="comments.length > 0" :class="$style.comments">
        <comment-display
          v-for="comment in comments"
          :key="comment.id"
          :comment="comment"
          inactive
        />
      </div>
      <div :class="$style.hint">
        <el-button type="text" @click="showDrawer">
          <template v-if="commentCount > comments.length">
            zu den Kommentaren
            <template v-if="commentCount - comments.length === 1">
              (+ ein älterer Kommentar)
            </template>
            <template v-else>
              (+ {{ commentCount - comments.length }} ältere Kommentare)
            </template>
          </template>
          <template v-else> zu den Kommentaren </template>
        </el-button>
      </div>
    </div>
    <template #reference
      ><comment-count :count="commentCount" :size="size" @click="showDrawer"
    /></template>
  </el-popover>
</template>

<script>
import CommentDisplay from "~/src/components/comments/CommentDisplay";
import CommentCount from "~/src/components/comments/CommentCount";

export default {
  name: "CommentPopover",
  components: { CommentCount, CommentDisplay },
  inject: ["openCommentDrawer"],
  props: {
    commentable: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: "medium",
    },
  },
  emits: ["update"],
  computed: {
    commentCount() {
      const { commentable } = this;
      return (
        (commentable && commentable.commentsCount) ||
        (commentable &&
          commentable.comments &&
          commentable.comments.totalCount) ||
        0
      );
    },
    comments() {
      const { commentable } = this;

      return (
        (commentable && commentable.comments && commentable.comments.nodes) ||
        []
      );
    },
  },
  methods: {
    showDrawer() {
      return this.openCommentDrawer(this.commentable).then((data) => {
        if (data) {
          this.$emit("update", data.comment);
          const c = {
            totalCount: data.comments.totalCount,
            nodes:
              data.comments.nodes.length > 0
                ? [data.comments.nodes.reverse()[0]]
                : [],
          };
          this.$set(this.commentable, "comments", c);
          this.$set(
            this.commentable,
            "commentsCount",
            data.comments.totalCount,
          );
        }
      });
    },
  },
};
</script>

<style lang="module.css" module>
.popper.popper {
  padding: 0;
}
.body {
  position: relative;
  word-break: normal;
  text-align: left;
}
.comments {
  max-height: 260px;
  overflow: hidden;
  padding: 12px 12px 40px;
}
.hint {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(to bottom, rgba(255,255,255,0.0), rgba(255,255,255,1) 50%);
  text-align: center;
}
</style>
