var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.wrap},[_c('div',{class:_vm.$style.inner},[_vm._t("pre"),_vm._v(" "),_c('div',[_vm._t("preMember"),_vm._v(" "),_c('family-member',{attrs:{"member":_vm.person,"emphasize-name":_vm.emphasizeName,"to":_vm.to}}),_vm._v(" "),_vm._t("postMember")],2),_vm._v(" "),_c('div',{class:_vm.indent ? _vm.$style.indent : null},[_vm._v("\n      "+_vm._s(_vm.person.street)+" "+_vm._s(_vm.person.streetNumber)+",\n      "+_vm._s(_vm.person.zip)+"\n      "),_c('karlsruhe-locality',{attrs:{"locality-and-district":_vm.person}}),_vm._v(" "),_vm._t("postAddress")],2),_vm._v(" "),_c('div',{class:_vm.indent ? _vm.$style.indent : null},[_c(_vm.infoTag,{tag:"router-link",attrs:{"to":{
          name: 'family',
          params: { familyId: _vm.person.familyId },
          query: {
            personId: _vm.person.id,
            tab: 'passports',
          },
        },"target":_vm.linkTarget}},[_vm._v(_vm._s(_vm.passports))]),_vm._v(",\n      "),_c(_vm.infoTag,{tag:"router-link",attrs:{"to":{
          name: 'family',
          params: { familyId: _vm.person.familyId },
          query: {
            personId: _vm.person.id,
            tab: 'documents',
          },
        },"target":_vm.linkTarget}},[_vm._v(_vm._s(_vm.documents))])],1)],2),_vm._v(" "),(_vm.$slots.actions)?_c('div',{class:[_vm.$style.actions, _vm.indent ? _vm.$style.indent : null]},[_vm._t("actions")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }