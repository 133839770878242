import Vue from "vue";
import axios from "axios";
import ReLoginOverlay from "~/src/components/common/layout/ReLoginOverlay";
import { configureVue } from "~/src/util/setup-vue";

const TAB_ID = `${Math.random().toString(36)}${Math.random().toString(36)}`;

configureVue();

export const ReactiveAuth = new Vue({
  name: "AppAuth",
  components: { ReLoginOverlay },
  data() {
    return {
      requiredRole: null,
      inited: false,
      userId: null,
      error: null,
      loading: false,
      name: null,
      email: null,
      role: null,
      rights: [],
      lastAuthEmail: null,
      needs2FA: null,
    };
  },
  computed: {
    loggedIn() {
      return this.userId !== null && !this.needs2FA;
    },
    loggingIn() {
      return this.loading;
    },
    safeName() {
      return this.name || "";
    },
    reLoginVisible() {
      const { inited, loggedIn, needs2FA, lastAuthEmail } = this;
      return inited && !loggedIn && !needs2FA && lastAuthEmail;
    },
  },
  methods: {
    setRequiredRole({ role }) {
      this.requiredRole = role;
    },
    hasRight(right) {
      if (right === "passport_read") {
        return (
          this.userId !== null &&
          this.rights &&
          (this.rights.indexOf("passport_read") > -1 ||
            this.rights.indexOf("passport_management") > -1 ||
            this.rights.indexOf("passport_management_destructive") > -1 ||
            this.rights.indexOf("all") > -1)
        );
      }
      return (
        this.userId !== null &&
        this.rights &&
        (this.rights.indexOf(right) > -1 || this.rights.indexOf("all") > -1)
      );
    },
    wrapLoginAxiosRequest(axiosRequest, ignoreErrors = false) {
      return axiosRequest
        .then((response) => {
          if (response.data.id) {
            if (this.requiredRole === response.data.role) {
              this.setStateLogin({
                userId: response.data.id.toString(),
                role: response.data.role,
                rights: response.data.rights,
                name: response.data.name,
                email: response.data.email,
                needs2FA: response.data.needs2FA,
              });
            } else {
              throw new Error(
                `You are role ${response.data.role} but this login is for ${this.requiredRole}`,
              );
            }
          } else {
            console.error(response);
            if (!ignoreErrors) {
              this.setStateLoginFailure({
                error: "cannot handle response",
              });
            } else {
              this.abortLogin();
            }
            throw new Error("login error");
          }
        })
        .catch((error) => {
          if (!ignoreErrors) {
            if (
              error.response &&
              error.response.data &&
              error.response.data.error
            ) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              this.setStateLoginFailure({
                error: error.response.data.error,
              });
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log("Error", error.message);
              this.setStateLoginFailure({
                error: error.message || error,
              });
            }
          } else {
            this.abortLogin();
          }
          throw new Error("login error");
        });
    },
    startLogin() {
      this.loading = true;
    },
    abortLogin() {
      this.loading = false;
    },
    setStateLogin({ userId, role, rights, name, email, needs2FA }) {
      this.inited = true;
      this.role = role;
      this.rights = rights;
      this.userId = userId.toString();
      this.name = name;
      this.email = email;
      this.error = null;
      this.loading = false;
      this.lastAuthEmail = email;
      this.needs2FA = needs2FA;
      notifyAuthChange(this);
    },
    setStateLoginFailure({ error }) {
      this.role = null;
      this.rights = [];
      this.userId = null;
      this.name = null;
      this.email = null;
      this.error = error;
      this.loading = false;
      this.lastAuthEmail = null;
      this.needs2FA = null;
      notifyAuthChange(this);
    },
    setStateAuthLost() {
      this.role = null;
      this.rights = [];
      this.userId = null;
      this.name = null;
      this.email = null;
      this.error = null;
      this.loading = false;
      this.needs2FA = null;
      notifyAuthChange(this);
    },
    markAsInited() {
      this.inited = true;
      notifyAuthChange(this);
    },
    getHeaders() {
      const csrfToken = (document.querySelector("meta[name=csrf-token]") || {})
        .content;
      const headers = {
        "X-Env": location.host,
      };
      if (csrfToken) {
        headers["X-CSRF-Token"] = csrfToken;
      }
      return headers;
    },
    logout() {
      if (!this.loggedIn) {
        return Promise.resolve(false);
      }
      this.startLogin();
      this.lastAuthEmail = null;
      return axios
        .delete("/users/sign_out", {
          headers: {
            ...this.getHeaders(),
          },
          responseType: "json",
        })
        .finally(() => {
          this.setStateAuthLost();
        });
    },
    authenticate({ email, password }) {
      this.startLogin();
      return this.wrapLoginAxiosRequest(
        axios.post(
          "/users/sign_in",
          {
            user: {
              email,
              password,
            },
          },
          {
            headers: {
              ...this.getHeaders(),
            },
            responseType: "json",
          },
        ),
      );
    },
    provide2FA({ code }) {
      this.startLogin();
      return this.wrapLoginAxiosRequest(
        axios.post(
          "/users/provide_2fa",
          {
            user: {
              code,
            },
          },
          {
            headers: {
              ...this.getHeaders(),
            },
            responseType: "json",
          },
        ),
      );
    },
    refreshSession(ignoreErrors = false) {
      this.startLogin();
      return this.wrapLoginAxiosRequest(
        axios.post(
          "/users/refresh",
          {},
          {
            headers: {
              ...this.getHeaders(),
            },
            responseType: "json",
          },
        ),
        ignoreErrors,
      );
    },
  },
  render(createElement) {
    const { reLoginVisible, lastAuthEmail, needs2FA, error } = this;
    if (reLoginVisible) {
      return createElement("div", { class: "auth auth__re-login" }, [
        createElement(ReLoginOverlay, {
          props: {
            visible: true,
            email: lastAuthEmail,
            error: error,
            needs2FA: needs2FA,
            doAuthenticate: this.authenticate,
            do2fa: this.provide2FA,
          },
        }),
      ]);
    }
    return createElement("div", { class: "auth" });
  },
});

const rootEl = document.createElement("div");
document.body.appendChild(rootEl);
ReactiveAuth.$mount(rootEl);

export function init() {
  return ReactiveAuth.refreshSession(true)
    .then(() => true)
    .catch((err) => {
      ReactiveAuth.markAsInited();
      return false;
    });
}

let authChangeCallbacks;

export function onAuthChange(callback) {
  if (!authChangeCallbacks) {
    authChangeCallbacks = [];
  }
  authChangeCallbacks.push(callback);
  return function removeCallback() {
    authChangeCallbacks.splice(authChangeCallbacks.indexOf(callback), 1);
  };
}

function notifyAuthChange(auth) {
  if (authChangeCallbacks) {
    for (let i = 0; i < authChangeCallbacks.length; i++) {
      authChangeCallbacks[i](auth);
    }
  }
}
