<template>
  <el-tooltip :disabled="!birthday" :tabindex="-1">
    <span :is="highlight ? 'mark' : 'span'">
      <locale-date v-if="birthday" :date="birthday" />
      <em v-else>?</em>
    </span>
    <template #content
      ><person-current-age v-if="birthday" :date="birthday"
    /></template>
  </el-tooltip>
</template>

<script>
import LocaleDate from "~/src/components/common/i18n/LocaleDate";
import PersonCurrentAge from "~/src/components/families/PersonCurrentAge";
import { toDate } from "~/src/util/date";

export default {
  name: "BirthdayLabel",
  components: { PersonCurrentAge, LocaleDate },
  inject: {
    highlightableWordsProvider: {
      from: "highlightableWordsProvider",
      default: null,
    },
  },
  props: {
    birthday: {
      type: [Object, String, Date],
      required: false,
      default: null,
    },
  },
  computed: {
    highlight() {
      const { highlightableWordsProvider, birthday } = this;
      return (
        highlightableWordsProvider &&
        highlightableWordsProvider.highlightableDate &&
        birthday &&
        highlightableWordsProvider.highlightableDate.toLocaleDateString() ===
          toDate(birthday).toLocaleDateString()
      );
    },
  },
};
</script>
