<template>
  <div
    v-if="needsPagination"
    :class="[$style.container, center ? $style.center : null]"
  >
    <el-button-group>
      <el-button
        :disabled="!hasPreviousPage"
        icon="el-icon-arrow-left"
        round
        size="small"
        @click="$emit('previous-page')"
      />
      <el-button
        :disabled="!hasNextPage"
        icon="el-icon-arrow-right"
        round
        size="small"
        @click="$emit('next-page')"
      />
    </el-button-group>
  </div>
</template>

<script>
export default {
  name: "AppPager",
  props: {
    hasPreviousPage: {
      type: Boolean,
      default: false,
    },
    hasNextPage: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    showAlways: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["previous-page", "next-page"],
  computed: {
    needsPagination() {
      const { hasPreviousPage, hasNextPage, showAlways } = this;
      return showAlways || hasPreviousPage || hasNextPage;
    },
  },
};
</script>

<style lang="module.scss" module>
.container {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.center {
  text-align: center;
}
</style>
