<script>
import gql from "graphql-tag";

const PROBABLY_PHONE =
  /iphone|android|ie|blackberry|fennec/.test(
    navigator.userAgent.toLowerCase(),
  ) && "ontouchstart" in document.documentElement;

export default {
  name: "PhoneNumber",
  props: {
    phone: {
      type: String,
      default: "",
    },
    includeInfo: {
      type: Boolean,
      default: false,
    },
    apollo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      info: null,
    };
  },
  watch: {
    includeInfo: {
      handler(to) {
        if (to) {
          this.request();
        } else {
          this.info = null;
        }
      },
    },
  },
  mounted() {
    if (this.includeInfo) {
      this.request();
    }
  },
  methods: {
    request() {
      (this.apollo || this.$apollo)
        .mutate({
          mutation: gql`
            mutation ($phone: String!) {
              payload: checkPhone(input: { phone: $phone }) {
                valid
                info
                formatted
                input
              }
            }
          `,
          variables: {
            phone: this.phone,
          },
        })
        .then(({ data: { payload } }) => {
          if (payload) {
            this.info = payload;
          } else {
            this.info = null;
          }
        })
        .catch((e) => {
          this.info = null;
        });
    },
  },
  render(createElement) {
    const { info, phone } = this;
    let tag = "span";
    let tagProp = {};

    if (PROBABLY_PHONE) {
      tag = "a";
      tagProp = {
        attrs: {
          href: `tel:${phone}`,
          title: "Telefon",
        },
      };
    }
    return createElement(
      "span",
      {
        class: "u-no-break",
      },
      [
        createElement(tag, tagProp, [
          createElement("em", { class: "el-icon-phone" }),
        ]),
        " ",
        createElement(tag, tagProp, this.$scopedSlots.default()),
        info && info.info ? ` (${info.info})` : "",
      ].filter((e) => !!e),
    );
  },
};
</script>