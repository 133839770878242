var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-panel',{attrs:{"no-border":_vm.noBorder}},[(_vm.$slots.filter)?_c('div',{class:_vm.$style.filter},[_vm._t("filter")],2):_vm._e(),_vm._v(" "),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"table",attrs:{"data":_vm.list,"row-key":"id","size":_vm.size,"stripe":"","height":_vm.tableHeight},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_vm._t("empty")]},proxy:true}],null,true)},[_c('el-table-column',{attrs:{"label":"Id","prop":"identifier","width":"110"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v("\n        "+_vm._s(row.identifier)+"\n      ")]}}])}),_vm._v(" "),(!_vm.hideStatus)?_c('el-table-column',{attrs:{"label":"Status","prop":"status","width":"105"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('application-status',{attrs:{"status":row.status}})]}}],null,false,278665096)}):_vm._e(),_vm._v(" "),(!_vm.hideApplicant)?_c('el-table-column',{attrs:{"label":"Antragsteller*in","prop":"applicant","min-width":"280"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('family-relation-detail',{attrs:{"person":row.applicant,"to":{
            name: 'family',
            params: {
              familyId: row.applicant.familyId,
            },
            query: {
              personId: row.applicant.id,
              tab: 'applications',
            },
          }}})]}}],null,false,2927281678)}):_vm._e(),_vm._v(" "),_c('el-table-column',{attrs:{"label":"Beantragt","prop":"items","min-width":"300"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('application-items-summary',{attrs:{"application":row}}),_c('br'),_vm._v("\n        "+_vm._s(row.notices.join(", "))+"\n      ")]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"Erstellt","prop":"createdAt","width":"92"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('locale-date-time',{attrs:{"date":row.createdAt,"two-lines":""}})]}}])}),_vm._v(" "),_vm._t("default",function(){return [_c('el-table-column')]},{"executeCommand":_vm.executeCommand}),_vm._v(" "),_c('comment-column',{attrs:{"fixed":_vm.commentColumnFixed},on:{"update":_vm.reload}})],2),_vm._v(" "),_c('data-table-footer',{attrs:{"has-next-page":_vm.hasNextPage,"has-previous-page":_vm.hasPreviousPage,"reload":_vm.reload},on:{"next-page":_vm.goToNextPage,"previous-page":_vm.goToPreviousPage},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true},{key:"results",fn:function(){return [_c('result-info',{attrs:{"total-count":_vm.totalCount,"page-info":_vm.pageInfo}})]},proxy:true}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }