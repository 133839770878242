import VueRouter from "vue-router";
import { ReactiveAuth } from "~/src/util/auth";

// authentication guard
// this will wait for the vuex store to be authenticated before any routing can happen

let isReady = ReactiveAuth.inited;
const pendingNavigation = {
  from: null,
  to: null,
  next: null,
};

if (!isReady) {
  const unwatch = ReactiveAuth.$watch("inited", function (to) {
    if (to) {
      unwatch();
      isReady = true;
      if (pendingNavigation.next) {
        authGuard(
          pendingNavigation.to,
          pendingNavigation.from,
          pendingNavigation.next,
        );
      }
    }
  });
}

function authGuard(to, from, next) {
  if (to.name !== "login" && ReactiveAuth.error) {
    next({ name: "login" });
    return;
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (ReactiveAuth.loggedIn && !ReactiveAuth.needs2FA) {
      next();
      return;
    }
    next({ name: "login" });
  } else if (to.matched.some((record) => record.meta.requiresNoAuth)) {
    if (!ReactiveAuth.loggedIn) {
      next();
      return;
    }
    next(false);
  } else {
    next();
  }
}

export function getRouter(routes, base) {
  const router = new VueRouter({
    mode: "history",
    base,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      return { x: 0, y: 0 };
    },
    routes,
  });
  router.beforeEach((to, from, next) => {
    if (isReady) {
      authGuard(to, from, next);
    } else {
      if (pendingNavigation.next) {
        next(false);
      } else {
        pendingNavigation.to = to;
        pendingNavigation.from = from;
        pendingNavigation.next = next;
      }
    }
  });

  return router;
}
