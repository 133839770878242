<template>
  <el-main class="Content Content_centered">
    <app-panel :class="$style.panel" main>
      <template #title
        ><h1 class="AppTitle">
          {{ title }}
        </h1></template
      >
      <el-form
        v-if="!show2fa"
        ref="form"
        :model="model"
        :rules="rules"
        label-position="top"
        label-suffix=": "
        @submit.native.prevent
      >
        <el-form-item label="E-Mail-Adresse" prop="email">
          <el-input
            id="email"
            v-model="model.email"
            autofocus
            @keypress.native.enter="$refs.passwordInput.focus()"
          />
        </el-form-item>
        <el-form-item label="Passwort" prop="password">
          <el-input
            id="password"
            ref="passwordInput"
            v-model="model.password"
            type="password"
            @keypress.native.enter="submit().catch(() => {})"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit().catch(() => {})">
            Login
          </el-button>
          <auth-meta-navi v-if="!skipMetaNav" />
        </el-form-item>
      </el-form>
      <el-form
        v-else
        ref="form2fa"
        :model="model"
        :rules="rules2fa"
        label-position="top"
        label-suffix=": "
        @submit.native.prevent
      >
        <el-alert
          :class="$style.alert"
          :closable="false"
          effect="dark"
          type="info"
          show-icon
        >
          Wir haben Ihnen gerade eine E-Mail mit einem Code geschickt. Bitte
          geben Sie diesen Code hier ein. Sie haben 15 Minuten dafür Zeit.
        </el-alert>
        <el-form-item label="Code" prop="code">
          <el-input
            ref="codeInput"
            v-model="model.code"
            @keypress.native.enter="submit2fa().catch(() => {})"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit2fa().catch(() => {})">
            Login
          </el-button>
        </el-form-item>
      </el-form>
    </app-panel>
  </el-main>
</template>

<script>
import AppPanel from "~/src/components/common/layout/AppPanel";
import AuthMetaNavi from "~/src/components/common/layout/AuthMetaNavi";
import { ensureValidForm } from "~/src/util/validation";

function getEmpty() {
  return {
    email: "",
    password: "",
    code: "",
  };
}

export default {
  name: "PageUserLogin",
  components: { AuthMetaNavi, AppPanel },
  props: {
    title: {
      type: String,
      default: "Login",
    },
    skipMetaNav: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      model: getEmpty(),
      show2fa: false,
    };
  },
  head() {
    return {
      title: "Login",
    };
  },
  computed: {
    rules() {
      return {
        password: [
          {
            required: true,
            type: "string",
            message: "Muss ausgefüllt werden",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            type: "email",
            message: "Muss eine valide E-Mail-Adresse sein",
            trigger: "blur",
          },
        ],
      };
    },
    rules2fa() {
      return {
        code: [
          {
            required: true,
            type: "string",
            message: "Muss ausgefüllt werden",
            trigger: "blur",
          },
        ],
      };
    },
  },
  methods: {
    onLoginSuccess() {
      this.$message({
        type: "success",
        message: "Sie sind nun eingeloggt",
        offset: 60,
      });
      this.$router.replace({ name: "index" });
    },
    submit() {
      return ensureValidForm(this.$refs.form, () => {
        const { email, password } = this.model;
        return this.$auth
          .authenticate({
            email,
            password,
          })
          .then((...args) => {
            if (this.$auth.needs2FA) {
              this.show2fa = true;
              this.$nextTick(() => this.$refs.codeInput.focus());
            } else {
              this.onLoginSuccess();
            }
          })
          .catch(() => {
            this.$message({
              type: "error",
              message: this.$auth.error,
              offset: 60,
            });
          });
      });
    },
    submit2fa() {
      return ensureValidForm(this.$refs.form2fa, () => {
        const { code } = this.model;
        return this.$auth
          .provide2FA({
            code,
          })
          .then((...args) => {
            this.onLoginSuccess();
          })
          .catch(() => {
            this.$message({
              type: "error",
              message: this.$auth.error,
              offset: 60,
            });
          });
      });
    },
  },
};
</script>

<style lang="module.scss" module>
.panel {
  width: 400px;
  max-width: calc(100vw - 40px);
}

.alert.alert {
  margin-bottom: 1rem;
}
</style>
