<script>
import { getCode, getLabel } from "~/src/components/common/i18n/country";

export default {
  name: "CountryCode",
  functional: true,
  props: {
    code: {
      type: String,
      required: true,
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
  },
  render(createElement, context) {
    const { code, tooltip } = context.props;
    if (tooltip) {
      const tip =
        code === "Z?"
          ? getLabel(code)
          : code === "Z!"
            ? `Staatsangehörigkeit unbekannt`
            : `Bürger*in von ${getLabel(code)}`;
      return createElement(
        "el-tooltip",
        {
          props: {
            content: tip,
          },
        },
        [createElement("span", getCode(code))],
      );
    }
    return createElement("span", getCode(code));
  },
};
</script>
