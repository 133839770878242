var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-main',{staticClass:"Content"},[(_vm.$root.hasRightPassportManagement)?_c('div',[_c('list-heading',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('navigation-button',{attrs:{"type":"primary","size":"small","icon":"el-icon-plus","to":{ name: 'new-application' }}},[_vm._v("\n          Neuer Antrag\n        ")]),_vm._v(" "),_c('navigation-button',{attrs:{"type":"primary","size":"small","icon":"el-icon-s-order","plain":"","to":{ name: 'applications' }}},[_vm._v("\n          Alle Anträge\n        ")])]},proxy:true}],null,false,3755598233)},[_vm._v("\n      Offene Anträge\n      ")]),_vm._v(" "),_c('application-list',{attrs:{"hide-status":"","loading":_vm.$apollo.queries.openApplications.loading,"list":_vm.openApplications.nodes,"total-count":_vm.openApplications.totalCount,"page-info":_vm.openApplications.pageInfo,"has-next-page":_vm.openApplications.pageInfo.hasNextPage,"has-previous-page":_vm.openApplications.pageInfo.hasPreviousPage,"go-to-next-page":_vm.nextOpenApplicationsPage,"go-to-previous-page":_vm.previousOpenApplicationsPage,"reload":_vm.reloadOpenApplications}},[_c('el-table-column',{attrs:{"width":"50"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('navigation-button',{attrs:{"to":{
              name: 'application',
              params: { applicationId: row.id },
            },"type":"primary","plain":"","circle":"","icon":"ka-icon-eye"}})]}}],null,false,855344069)})],1)],1):_vm._e(),_vm._v(" "),(
      _vm.$root.hasRightPassportRead ||
      _vm.$root.hasRightStatistics ||
      _vm.$root.hasRightEmployeeManagement
    )?_c('div',[_c('action-list',{attrs:{"margin":"","center":""}},[(_vm.$root.hasRightPassportRead)?_c('navigation-button',{attrs:{"icon":"el-icon-user-solid","to":{ name: 'families' }}},[_vm._v("\n        Familie/Person suchen\n      ")]):_vm._e(),_vm._v(" "),(_vm.$root.hasRightStatistics)?_c('navigation-button',{attrs:{"icon":"el-icon-data-analysis","to":{ name: 'statistics' }}},[_vm._v("\n        Zur Statistik-Auswertung\n      ")]):_vm._e(),_vm._v(" "),(_vm.$root.hasRightEmployeeManagement)?_c('navigation-button',{attrs:{"icon":"el-icon-s-custom","to":{ name: 'employees' }}},[_vm._v("\n        Zur Mitarbeitenden-Verwaltung\n      ")]):_vm._e()],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }